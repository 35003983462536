export interface UpManFileUploadResponse {
  msg: string;
  files: UpManFile[];
}

export interface UpManFile {
  fileId: string;
  uploaderId: string;
  fileSize: number;
  tenantId: string;
  entityId: string;
  usecase: UpManUseCase;
  tags: string[];
  filename: string;
  s3bucket: string;
  s3objectname: string;
  publicPath: string;
  mimetype: string;
  fileExtension: string;
}

export interface UpManFileUploadDto {
  tenantId: string;
  entityId: string;
  usecase: UpManUseCase;
  nfile: File;
  isDownloadable: boolean;
}

export enum UpManUseCase {
  PollChoice = "PollChoice", // 256x256
  Thumbnail = "Thumbnail", // 344x194
  Logo = "Logo", // 256x256
  FullHD = "FullHD", // 1920x1080
  Storefile = "Storefile", // no conversion, just stored
}

export interface UpManGeneralFile {
  upmanId: string;
  uploaderId: string;
  fileSize: number;
  tags: string[];
  filename: string;
  publicPath: string;
  fileExtension: string;
}

export enum UpManEntityPrefix {
  Broadcast = "bc_",
  Poll = "poll_",
}
